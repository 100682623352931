export const PERMISSIONS = {
  cut: {
    view: 'cut.view',
    edit: 'cut.edit',
    editCompletion: 'cut.editCompletion',
    executor: 'cut.executor'
  },
  edge: {
    view: 'edge.view',
    edit: 'edge.edit',
    editCompletion: 'edge.editCompletion',
    executor: 'edge.executor'
  },
  additive: {
    view: 'additive.view',
    edit: 'additive.edit',
    editCompletion: 'additive.editCompletion',
    executor: 'additive.executor'
  },
  build: {
    view: 'build.view',
    create: 'build.create',
    edit: 'build.edit',
    remove: 'build.remove',
    executor: 'build.executor'
  },
  marriageBulkhead: {
    view: 'marriageBulkhead.view',
    create: 'marriageBulkhead.create',
    edit: 'marriageBulkhead.edit',
    remove: 'marriageBulkhead.remove',
    executor: 'marriageBulkhead.executor'
  },
  mirror: {
    view: 'mirror.view',
    create: 'mirror.create',
    edit: 'mirror.edit',
    remove: 'mirror.remove',
    executor: 'mirror.executor'
  },
  pack: {
    view: 'pack.view',
    edit: 'pack.edit',
    editCompletion: 'pack.editCompletion',
    executor: 'pack.executor'
  },
  user: {
    view: 'user.view',
    create: 'user.create',
    edit: 'user.edit',
    remove: 'user.remove',
    recovery: 'product.recovery'
  },
  role: {
    view: 'role.view',
    create: 'role.create',
    edit: 'role.edit',
    remove: 'role.remove'
  },
  manufacturer: {
    view: 'manufacturer.view',
    create: 'manufacturer.create',
    edit: 'manufacturer.edit',
    remove: 'manufacturer.remove',
    analytic: 'manufacturer.analytic'
  },
  product: {
    view: 'product.view',
    job: 'product.job',
    pay: 'product.pay',
    create: 'product.create',
    edit: 'product.edit',
    remove: 'product.remove',
    recovery: 'product.recovery'
  },
  stock: {
    view: 'stock.view',
    create: 'stock.create',
    edit: 'stock.edit',
    transfer: 'stock.transfer',
    remove: 'stock.remove',
    recovery: 'stock.recovery',
    analytic: 'stock.analytic'
  },
  booker: {
    pay: 'booker.pay'
  },
  dictionary: {
    sheetFormat: {
      view: 'dictionary.sheetFormat.view',
      create: 'dictionary.sheetFormat.create',
      edit: 'dictionary.sheetFormat.edit',
      remove: 'dictionary.sheetFormat.remove',
      recovery: 'dictionary.sheetFormat.recovery'
    }
  },
  profile: {
    source: 'source.view',
    workPlanTelegram: 'workPlanTelegram.connect',
    paymentTelegram: 'paymentTelegram.connect'
  },
  comment: {
    view: 'comment.view',
    create: 'comment.create',
    edit: 'comment.edit',
    editAll: 'comment.editAll',
    remove: 'comment.remove',
    removeAll: 'comment.removeAll'
  },
  pricePolicy: {
    view: 'pricePolicy.view',
    create: 'pricePolicy.create',
    edit: 'pricePolicy.edit',
    remove: 'pricePolicy.remove'
  },
  tag: {
    view: 'tag.view',
    create: 'tag.create',
    edit: 'tag.edit',
    remove: 'tag.remove'
  }
}
